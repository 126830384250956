import * as React from "react";
import classNames from "classnames";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { StaticImage } from "gatsby-plugin-image";
import BtnFilled from "./reusable/BtnFilled";

import Slider from "react-slick";
// markup

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CaseStudiesList = () => {
  const breakpoints = useBreakpoint();
  return (
    <>
      <div className="item case-studies-teaser">
        <div className="inner">
          <div className="text">
            <div className="tag">
              <span>Ecommerce Product Discovery Platform</span>
            </div>
            <h3>
              <b>
                Modernized Ecommerce Product Discovery Platform for the largest
                South American retailer
              </b>
            </h3>
            {breakpoints.sm && (
              <figure>
                <StaticImage
                  src="./../images/casestudy/product-discovery-platform.jpg"
                  alt="Ecommerce Product Discovery Platform"
                  layout="fullWidth"
                  loading="lazy"
                  placeholder="blurred"
                />
              </figure>
            )}

            <p>
              Built a multi-tenant AI-powered search platform to replace the
              existing third-party solution to support their growing business in
              7 countries, resulting in 12% increase in conversion rate and $48
              million increase in projected revenue.
            </p>
            <div className="cta">
              <BtnFilled to="/case-studies/intelligent-product-discovery-platform">
                Continue Reading
              </BtnFilled>
            </div>
          </div>
          {!breakpoints.sm && (
            <figure>
              <StaticImage
                src="./../images/casestudy/product-discovery-platform.jpg"
                alt="Ecommerce Product Discovery Platform"
                layout="fullWidth"
                loading="lazy"
                placeholder="blurred"
              />
            </figure>
          )}
        </div>
      </div>
      <div className="item case-studies-teaser">
        <div className="inner">
          <div className="text">
            <div className="tag">
              <span>Search and Recommendation Platform</span>
            </div>
            <h3>
              <b>
                Product Search and Recommendation Platform for the 2nd largest
                home improvement retailer in US
              </b>
            </h3>
            {breakpoints.sm && (
              <figure>
                <StaticImage
                  src="./../images/casestudy/aiml-platform.jpg"
                  alt="End-to-end AI-ML platform for retail"
                  layout="fullWidth"
                  loading="lazy"
                  placeholder="blurred"
                />
              </figure>
            )}
            <p>
              Build a scalable AI-powered eCommerce search and recommendation
              platform for a catalog of 10 million products, resulting in annual
              sales growth of $100 million.
            </p>
            <div className="cta">
              <BtnFilled to="/case-studies/machine-learning-for-ai-powered-search">
                Continue Reading
              </BtnFilled>
            </div>
          </div>
          {!breakpoints.sm && (
            <figure>
              <StaticImage
                src="./../images/casestudy/aiml-platform.jpg"
                alt="End-to-end AI-ML platform for retail"
                layout="fullWidth"
                placeholder="blurred"
              />
            </figure>
          )}
        </div>
      </div>
      <div className="item case-studies-teaser">
        <div className="inner">
          <div className="text">
            <div className="tag">
              <span>Clickstream Platform</span>
            </div>
            <h3>
              <b>
                Clickstream Data Platform for one of the leading south-Asian
                ecommerce company
              </b>
            </h3>
            {breakpoints.sm && (
              <figure>
                <StaticImage
                  src="./../images/casestudy/clickstream.jpg"
                  alt="Clickstream and data platform for ecommerce"
                  layout="fullWidth"
                  loading="lazy"
                  placeholder="blurred"
                />
              </figure>
            )}
            <p>
              Implemented a Snowplow Behavioral Data Platform to capture
              clickstream data to track real time impressions for mobile and web
              in 60 days, with 0 data loss, and capable of handling 50 million
              clickstream events per day.
            </p>
            <div className="cta">
              <BtnFilled to="/case-studies/clickstream-data-platform">
                Continue Reading
              </BtnFilled>
            </div>
          </div>
          {!breakpoints.sm && (
            <figure>
              <StaticImage
                src="./../images/casestudy/clickstream.jpg"
                alt="Clickstream and data platform for ecommerce"
                layout="fullWidth"
                loading="lazy"
                placeholder="blurred"
              />
            </figure>
          )}
        </div>
      </div>
    </>
  );
};

const MobileCarousel = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const breakpoints = useBreakpoint();
  return (
    <div className="items mobile">
      <Slider {...settings}>
        <div className="item case-studies-teaser">
          <div className="inner">
            <div className="text">
              <div className="tag">
                <span>Ecommerce Product Discovery Platform</span>
              </div>
              <h3>
                <b>
                  Modernized Ecommerce Product Discovery Platform for the
                  largest South American retailer
                </b>
              </h3>
              {breakpoints.sm && (
                <figure>
                  <StaticImage
                    src="./../images/casestudy/product-discovery-platform.jpg"
                    alt="Ecommerce Product Discovery Platform"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="blurred"
                  />
                </figure>
              )}

              <p>
                Built a multi-tenant AI-powered search platform to replace the
                existing third-party solution to support their growing business
                in 7 countries, resulting in 12% increase in conversion rate and
                $48 million increase in projected revenue.
              </p>
              <div className="cta">
                <BtnFilled to="/case-studies/intelligent-product-discovery-platform">
                  Continue Reading
                </BtnFilled>
              </div>
            </div>
            {!breakpoints.sm && (
              <figure>
                <StaticImage
                  src="./../images/casestudy/product-discovery-platform.jpg"
                  alt="Ecommerce Product Discovery Platform"
                  layout="fullWidth"
                  loading="lazy"
                  placeholder="blurred"
                />
              </figure>
            )}
          </div>
        </div>
        <div className="item case-studies-teaser">
          <div className="inner">
            <div className="text">
              <div className="tag">
                <span>Search and Recommendation Platform</span>
              </div>
              <h3>
                <b>
                  Product Search and Recommendation Platform for the 2nd largest
                  home improvement retailer in US
                </b>
              </h3>
              {breakpoints.sm && (
                <figure>
                  <StaticImage
                    src="./../images/casestudy/aiml-platform.jpg"
                    alt="End-to-end AI-ML Platform for Retail"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="blurred"
                  />
                </figure>
              )}
              <p>
                Build a scalable AI-powered eCommerce search and recommendation
                platform for a catalog of 10 million products, resulting in
                annual sales growth of $100 million.
              </p>
              <div className="cta">
                <BtnFilled to="case-studies/machine-learning-for-ai-powered-search">
                  Continue Reading
                </BtnFilled>
              </div>
            </div>
            {!breakpoints.sm && (
              <figure>
                <StaticImage
                  src="./../images/casestudy/aiml-platform.jpg"
                  alt="End-to-end AI-ML Platform for Retail"
                  layout="fullWidth"
                  loading="lazy"
                  placeholder="blurred"
                />
              </figure>
            )}
          </div>
        </div>
        <div className="item case-studies-teaser">
          <div className="inner">
            <div className="text">
              <div className="tag">
                <span>Clickstream Platform</span>
              </div>
              <h3>
                <b>
                  Clickstream Data Platform for one of the leading south-Asian
                  ecommerce company
                </b>
              </h3>
              {breakpoints.sm && (
                <figure>
                  <StaticImage
                    src="./../images/casestudy/clickstream.jpg"
                    alt="Clickstream and data platform for ecommerce"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="blurred"
                  />
                </figure>
              )}
              <p>
                Implemented a Snowplow Behavioral Data Platform to capture
                clickstream data to track real time impressions for mobile and
                web in 60 days, with 0 data loss, and capable of handling 50
                million clickstream events per day.
              </p>
              <div className="cta">
                <BtnFilled to="/case-studies/clickstream-data-platform">
                  Continue Reading
                </BtnFilled>
              </div>
            </div>
            {!breakpoints.sm && (
              <figure>
                <StaticImage
                  src="./../images/casestudy/clickstream.jpg"
                  alt="Clickstream and data platform for ecommerce"
                  layout="fullWidth"
                  loading="lazy"
                  placeholder="blurred"
                />
              </figure>
            )}
          </div>
        </div>
      </Slider>
    </div>
  );
};

const CasestudiesCarousel = ({ type = "carousel", className = "" }) => {
  return (
    <div
      className={classNames(
        "case-studies-carousel",
        type !== "carousel" && "non-carousel",
        className
      )}
    >
      {type === "carousel" && <MobileCarousel />}
      {type !== "carousel" && <CaseStudiesList />}
    </div>
  );
};

export default CasestudiesCarousel;
